import { Logger } from '../commons/Logger';
import { toString } from '../commons/HelperFunctions';
import { sendAdSenseEvent } from './AdSenseEvent';
import { Settings } from '../commons/Constants';

// global event tracker object to store any variables needed for event publishing via callback
const eventTrackerObj = {};

/**
 * Store necessary properties in a tracker object.
 * @param landerConfig
 * @param queryConfig
 */
export function setupCallbackTracker(landerConfig, queryConfig) {
  eventTrackerObj.landerConfig = landerConfig;
  eventTrackerObj.queryConfig = queryConfig;
}

export function getCafStatus() {
  return eventTrackerObj.cafStatus ? eventTrackerObj.cafStatus : null;
}

/**
 * The 'pageLoadedCallback' parameter specifies a callback function to be called when the page has loaded or when a
 * request is blocked by Google.
 * Allows an action to be performed when the page has loaded or when a request is blocked by Google.
 * The callback function takes a boolean and a JSON object. The boolean is set to true when the request is accepted and
 * false when it is rejected by Google.
 * The JSON object contains the following fields:
 * - adult
 * - error_code
 * - faillisted
 * - faillist_reason
 * - client
 * - needsreview
 * - feed
 * - user_search
 * - query
 * @param {*} requestAccepted
 * @param {*} status
 */
export function cafReturnedWithClosure(handlereRuestAcceptedState) {
  return function cafReturned(requestAccepted, status) {
    Logger.info('caf returned: requestAccepted=' + requestAccepted + ', status=' + toString(status));
    eventTrackerObj.cafStatus = status;
  
    if (Settings.VERIZON_FEED_ENABLE !== 'false') {
      handlereRuestAcceptedState(requestAccepted)
    }
    if (requestAccepted) {
      try {
        sendAdSenseEvent(eventTrackerObj.landerConfig, eventTrackerObj.queryConfig, status);
      } catch(err) {
        Logger.error('unexpected error in google ads request=', + err)
      }
    }
  }
}

/**
 * Callback function to be called when the ads have loaded or when no ads are served.
 * @param {string} containerName the container name of the ad or related search block.
 * @param {boolean} adsLoaded set to true when there is at least one ad to display and false when there are no ads returned.
 */
export function adsLoaded(containerName, adsLoaded) {
  if (adsLoaded) {
    try {
      Logger.debug('Ads are loaded');
    } catch (e) {
      Logger.error('Error in adsLoaded callback function: ' + toString(e));
    }
  } else {
    Logger.error('No ad coverage from Google');
  }
}

/**
 * Callback function to be called when the related searches have loaded or when no related searches are served.
 * @param {string} containerName the container name of the ad or related search block.
 * @param {boolean} adsLoaded set to true when there is at least one related search term to display and false when there
 * are no related searches returned.
 */
export function relatedLinksLoaded(containerName, adsLoaded) {
  if (adsLoaded) {
    try {
      Logger.debug('Related Links are loaded');
    } catch (e) {
      Logger.error('Error in relatedLinksLoaded callback function: ' + toString(e));
    }
  } else {
    Logger.error('No related links coverage from Google');
  }
}
