import { EventType, QueryParam, Settings } from '../commons/Constants';
import { KeywordOrigin, GoogleCaf } from './AdSense';
import {
  createBaseLanderEvent,
  eventToJsonString
} from '../commons/LanderEvent';
import { getCafStatus } from './AdSenseCallback';
import postEvent from '../commons/LanderEvent';
import {
  createPartnerCallbackEvent,
  getCallbackAdClickURL
} from '../commons/PartnersCallbackEvent';

/**
 * Send AdSense events to the future events API
 * @param landerConfig
 * @param queryConfig
 * @param cafStatus
 */
export function sendAdSenseEvent(landerConfig, queryConfig, cafStatus) {
  // Send event to internal system
  const adSenseEvent = createAdSenseEvent(landerConfig, queryConfig, cafStatus);
  postEvent(Settings.EVENT_PUBLISH_API, adSenseEvent);

  // Partner's callback event
  if (landerConfig.lander.eventCallbackUrl) {
    const partnersEvent = createPartnerCallbackEvent(adSenseEvent, queryConfig);
    postEvent(landerConfig.lander.eventCallbackUrl, partnersEvent);
  }
}

/**
 * Build AdSense Event
 * @param landerConfig - domain configuration
 * @param queryConfig  - query params
 * @param cafStatus    - CAF feed status, this value can be `null`
 * @param eventType    - Event Type can be passed in
 * @returns {json}
 */
export function createAdSenseEvent(
  landerConfig,
  queryConfig,
  cafStatus,
  eventType = null
) {
  eventType = eventType ? eventType : getAdSenseEventType(cafStatus);
  const event = createBaseLanderEvent(eventType, landerConfig);

  // Add AdSense related fields

  // defaults to the first item if s_token is an array
  if (Array.isArray(queryConfig[QueryParam.S_TOKEN])) {
    event.sToken = queryConfig[QueryParam.S_TOKEN][0];
  } else {
    event.sToken = queryConfig[QueryParam.S_TOKEN];
  }

  event.isAdult = cafStatus ? cafStatus.adult : null;
  event.keyword = getKeywordClickedOrSearch(cafStatus, queryConfig);
  event.keywordOrigin = getAdSenseKeywordOrigins(landerConfig, queryConfig);
  event.feedStatusErrorCode = cafStatus ? cafStatus.error_code : null;

  return event;
}

/**
 * Create click track url to be used as a callback url for CAF ad clicks
 * @param landerConfig
 * @param queryConfig
 * @returns {Array}
 */
export function buildClickTrackUrl(landerConfig, queryConfig) {
  const clickTrackUrls = [];

  // Internal event system
  const clickEvent = createAdSenseEvent(
    landerConfig,
    queryConfig,
    getCafStatus(),
    EventType.AD_CLICK
  );
  const clickEventJson = eventToJsonString(clickEvent);
  const clickEventTrackUrl =
    Settings.EVENT_PUBLISH_API + '?json=' + encodeURIComponent(clickEventJson);

  clickTrackUrls.push(clickEventTrackUrl);

  // Partner's callback events for Ad click (via Google CAF)
  if (landerConfig.lander.eventCallbackUrl) {
    clickTrackUrls.push(
      getCallbackAdClickURL(landerConfig, queryConfig, clickEvent)
    );
  }

  return clickTrackUrls;
}

// Get keyword clicked or search by the user
export function getKeywordClickedOrSearch(cafStatus, queryConfig) {
  // CAF always returns the last keyword in case of multiple 'query' URL parameters
  if (cafStatus && cafStatus.query !== 'undefined') {
    return cafStatus.query;
  }
  // URL param `query=keyword` is added via CAF
  const cafQuery = queryConfig[GoogleCaf.queryParam.QUERY];
  // Get the latest keyword in case of multiple queries
  if (Array.isArray(cafQuery)) {
    return cafQuery.slice(-1)[0];
  }
  return cafQuery;
}

/**
 * Get AdSense event type: click, visit, keyword search, search, etc.
 * @param cafStatus - CAF feed status
 * @returns {string}
 */
export function getAdSenseEventType(cafStatus) {
  let eventType = EventType.VISIT; // defaults is page visit

  if (typeof cafStatus !== 'object') {
    return eventType;
  }

  if (cafStatus.user_search === true) {
    eventType = EventType.KEYWORD_SEARCH;
  } else if (typeof cafStatus.query !== 'undefined') {
    eventType = EventType.KEYWORD_CLICK;
  }

  return eventType;
}

/**
 * Identify where does the related keywords on park page come from
 * @param landerConfig
 * @param queryConfig
 * @returns {string}
 */
export function getAdSenseKeywordOrigins(landerConfig, queryConfig) {
  // from query params - check for `?term` array
  if (queryConfig[QueryParam.TERM] || [].length > 0) {
    return KeywordOrigin.QUERY_PARAMS;
  }

  // from lander-param API - check for lander.adSense.keywords array
  if (landerConfig.adSense.keywords || [].length > 0) {
    return KeywordOrigin.DOMAIN_CONFIG;
  }

  // defaults to feed provider
  return KeywordOrigin.FEED_PROVIDER;
}
